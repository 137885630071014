import { PermissionCode } from "@const/PermissionCode";
import { RoleManager } from "@services/role-manager";
// import { environment } from "@env/environment";
// import { EnvType } from "@env/type";

export const sideMenu: Array<SidebarMenuItem> = [
  {
    name: 'Profiles',
    id: 'profiles',
    link: '',
    children: [
      {
        name: 'Users',
        link: 'users',
        permissions: [PermissionCode.sidebar.users]
      },
      {
        name: 'Customers',
        link: 'customers',
        permissions: [PermissionCode.sidebar.clients]
      },
      {
        name: 'Carriers',
        link: 'carriers',
        permissions: [PermissionCode.sidebar.carriers]
      },
      {
        name: 'Drivers',
        link: 'drivers',
        permissions: [PermissionCode.sidebar.drivers]
      },
      {
        name: 'Developers',
        link: 'developers',
        permissions: [PermissionCode.sidebar.developers]
      },
      {
        name: 'Locations',
        link: 'locations',
        permissions: [PermissionCode.sidebar.warehouses]
      },
      {
        name: 'Customer Locations',
        link: 'customer-locations',
        permissions: [PermissionCode.sidebar.warehouses]
      },
      {
        name: 'Customer Lanes',
        link: 'customer-lanes',
        permissions: [PermissionCode.sidebar.clients]
      },
    ],
    nzIcon: 'idcard',
  },
  {
    name: 'Order Management',
    id: 'order-management',
    link: '',
    children: [
      {
        name: 'Shipments',
        link: 'orders',
        permissions: [PermissionCode.sidebar.orders]
      },
      {
        name: 'Shipment Queue',
        link: 'shipment-queue',
        permissions: [PermissionCode.sidebar.shipment_queue]
      },
      {
        name: 'Quick Order',
        link: 'quick-orders',
        permissions: [PermissionCode.sidebar.quick_orders]
      },
      {
        name: 'Freight Quote v2',
        link: 'freight-quote-ftl',
        permissions: [PermissionCode.sidebar.freight_quote_ftl]
      },
      {
        name: 'Marketplace',
        link: 'marketplace',
        permissions: [PermissionCode.sidebar.marketplace]
      }
    ],
    nzIcon: 'build'
  },
  {
    name: 'Route Management',
    id: 'route-management',
    link: '',
    children: [
      {
        name: 'Dispatch',
        link: 'dispatch',
        permissions: [PermissionCode.sidebar.dispatch]
      },
      {
        name: 'Carrier Sales',
        link: 'carrier-sales',
        permissions: [PermissionCode.sidebar.carrier_sales]
      },
      {
        name: 'Carrier Pools',
        link: 'pools',
        permissions: [PermissionCode.sidebar.pools]
      },
      {
        name: 'Zip Rate',
        link: 'zip-rate',
        permissions: [PermissionCode.sidebar.zip_rate]
      },
      {
        name: 'Unsubscribe Email/SMS',
        link: 'comm-unsubscribes',
        permissions: [PermissionCode.sidebar.comm_unsubscribes]
      },
      {
        name: "DAT Truck",
        link: "dat/truck",
        permissions: [PermissionCode.sidebar.carrier_sales]
      },
      {
        name: "DAT Posts",
        link: "dat/post",
        permissions: [PermissionCode.sidebar.carrier_sales]
      }
    ],
    nzIcon: 'node-index'
  },
  {
    name: 'Planning Tools',
    id: 'planning',
    link: 'planning',
    children: [
      {
        name: 'Routing LTL',
        link: 'routing-ltl',
        permissions: [PermissionCode.sidebar.routing_ltl],
      },
      {
        name: 'Appointment',
        link: 'appointment',
        isUseParentPath: true,
        permissions: [PermissionCode.sidebar.appointment]
      },
      {
        name: 'Need Routing',
        link: 'need-routing',
        isUseParentPath: true,
        permissions: [PermissionCode.sidebar.need_routing]
      },
      {
        name: 'Planning Sessions',
        link: 'sessions',
        isUseParentPath: true,
        permissions: [PermissionCode.sidebar.planning_session]
      },
      {
        name: 'Quick Routing',
        link: 'single',
        isUseParentPath: true,
        permissions: [PermissionCode.sidebar.quick_routing]
      },
      {
        name: 'Linehaul Lanes',
        link: 'linehauls',
        isUseParentPath: true,
        permissions: [PermissionCode.sidebar.linehaul_lane]
      },
      {
        name: 'Historical Volume',
        link: 'volume',
        isUseParentPath: true,
        permissions: [PermissionCode.sidebar.historical_volume]
      }
    ],
    nzIcon: 'branches'
  },
  {
    name: 'Cross Dock Management',
    id: 'cross-dock',
    link: 'cross-dock',
    children: [
      {
        name: 'Shipments',
        link: 'shipments',
        hilight: false,
        isUseParentPath: true,
        permissions: [PermissionCode.sidebar.warehouse_shipments]
      },
      {
        name: 'Inventory',
        link: 'inventories',
        hilight: false,
        isUseParentPath: true,
        permissions: [PermissionCode.sidebar.warehouse_inventories]
      },
      {
        name: 'Rate Plans',
        link: 'rate-configuration',
        hilight: false,
        isUseParentPath: true,
        permissions: [PermissionCode.sidebar.rate_plans]
      },
      {
        name: 'Manifests',
        link: 'manifests',
        hilight: false,
        isUseParentPath: true,
        permissions: [PermissionCode.sidebar.manifests]
      },
      {
        name: 'External Route Connecting',
        link: 'external-route-connecting',
        hilight: false,
        isUseParentPath: true,
        permissions: [PermissionCode.sidebar.external_route_connecting]
      }
    ],
    nzIcon: 'swap'
  },
  {
    name: 'Accounting',
    id: 'accounting',
    link: 'finance',
    children: [
      {
        name: 'Invoice Shipments',
        link: 'invoices',
        isUseParentPath: true,
        permissions: [PermissionCode.sidebar.invoice_shipments]
      },
      {
        name: 'Transactions',
        link: 'transactions',
        isUseParentPath: true,
        permissions: [PermissionCode.sidebar.transactions]
      },
      {
        name: 'Accounts Payable Finance',
        link: 'ap',
        isUseParentPath: true,
        permissions: [PermissionCode.sidebar.transactions]
      },
      {
        name: 'Accounts Receivable Finance',
        link: 'ar',
        isUseParentPath: true,
        permissions: [PermissionCode.sidebar.transactions]
      },
      {
        name: 'Quick upload POD',
        link: 'quick-upload-pod',
        specificParentPath: 'finance-v2',
        permissions: [PermissionCode.sidebar.quick_upload_pod]
      },
      {
        name: "Request POD",
        link: "provide-pod",
        specificParentPath: 'finance-v2',
        isUseParentPath: true,
        permissions: [PermissionCode.sidebar.provide_pod]
      },
      {
        name: 'Reports',
        link: 'reports',
        specificParentPath: 'finance-v2',
        permissions: [PermissionCode.sidebar.report_finance]
      },
      {
        name: 'Non Factored (over 30days)',
        link: 'non-factored',
        specificParentPath: 'finance-v2',
        permissions: [PermissionCode.sidebar.report_finance] //sửa lại permission
      },
      {
        name: 'POD Confirmation',
        link: 'pod-confirmation',
        specificParentPath: 'finance-v2',
        permissions: [PermissionCode.sidebar.pod_confirmation]
      },
      {
        name: 'AP Statement - Walmart',
        link: 'qb-statement',
        isUseParentPath: true,
        permissions: [PermissionCode.sidebar.quickbook_statement_bill]
      }
    ],
    nzIcon: 'dollar',
  },
  {
    name: 'Communication Tools',
    id: 'communication-tools',
    link: '',
    children: [
      {
        name: 'Announcements',
        link: 'announcements',
        permissions: [PermissionCode.sidebar.announcements]
      },
      {
        name: 'Templates',
        link: 'templates',
        permissions: [PermissionCode.sidebar.templates]
      },
      {
        name: 'Textract Template',
        link: 'custom-textract-template',
        permissions: [PermissionCode.sidebar.custom_textract_template]
      }
    ],
    nzIcon: 'notification',
  },
  {
    name: 'Reports',
    id: 'report',
    link: 'report',
    children: [
      {
        name: 'Report Revenue',
        link: 'revenue',
        isUseParentPath: true,
        permissions: [PermissionCode.sidebar.report_revenue]
      },
      {
        name: 'Report Performance',
        link: 'performance',
        isUseParentPath: true,
        permissions: [PermissionCode.sidebar.report_performance]
      },
      {
        name: 'Loads Booked',
        link: 'loads-booked',
        isUseParentPath: true,
        permissions: [PermissionCode.sidebar.load_booked]
      },
      {
        name: 'Revenue Booked',
        link: 'revenue-booked',
        isUseParentPath: true,
        permissions: [PermissionCode.sidebar.revenue_booked]
      },
      {
        name: 'Report POD',
        link: 'pod',
        isUseParentPath: true,
        permissions: [PermissionCode.sidebar.report_pod]
      },
      {
        name: 'Viewer',
        link: 'viewer',
        isUseParentPath: true,
        permissions: [PermissionCode.sidebar.report_viewer]
      }
    ],
    nzIcon: 'line-chart',
  },
  // {
  //   name: 'Assignment',
  //   id: 'assignment',
  //   link: 'assignment',
  //   nzIcon: 'alert',
  //   children: [
  //     {
  //       name: "Request Carriers Provide POD",
  //       link: "provide-pod",
  //       isUseParentPath: true,
  //       roles: [RoleManager.admin, RoleManager.adminReadOnly]
  //     }
  //   ]
  // },
  {
    name: 'Export',
    id: 'export',
    link: 'export',
    nzIcon: 'export',
    children: [
      {
        name: 'Dispatch Dashboard',
        link: 'dispatch-dashboard',
        isUseParentPath: true,
        permissions: [PermissionCode.sidebar.dispatch_dashboard]
      },
    ]
  },
  {
    name: 'Settings',
    id: 'settings',
    link: 'settings',
    nzIcon: 'setting',
    children: [
      {
        name: '2-FA Settings',
        link: 'two-factor-authentication',
        nzIcon: 'safety',
        isUseParentPath: true,
        permissions: [PermissionCode.sidebar.two_factor_authentication]
      },
    ]
  }
];
